<template>
    <!-- <img src="../../assets/back.png" alt="" class="bg-img" /> -->
    <div style="background: #000">
        <div class="container">
            <div class="bankname">
                <img src="../../../assets/left.svg" alt="" />
                <span class="name">采日项目进度看板</span>
                <img src="../../../assets/right.svg" alt="" />
            </div>
            <Select
                v-model:value="projectCode"
                :options="projectList"
                labelKey="value"
                valueKey="key"
                style="width: 200px; margin: 20px 0"
            />
            <a-empty v-show="!workorderList.length" />
            <div
                class="table-wrapper"
                v-for="(table, tableIndex) in workorderList"
                :index="tableIndex"
            >
                <div class="left">
                    <div class="row">
                        <div v-for="(item, index) in leftColumns" :key="index">
                            {{ item.title }}
                        </div>
                    </div>
                    <div
                        class="row"
                        v-for="(item, index) in table.leftData?.filter((item, index) => {
                            return index >= i && index < i + step;
                        })"
                        :key="index"
                    >
                        <div v-for="(uu, uuIndex) in item" :key="uuIndex">
                            {{ uu }}
                        </div>
                    </div>
                </div>
                <div class="middle">
                    <div class="row">
                        <div v-for="(item, index) in table.stepNames" :key="index">
                            {{ item }}
                        </div>
                    </div>
                    <div
                        class="row"
                        v-for="(item, index) in table.middleData?.filter((item, index) => {
                            return index >= i && index < i + step;
                        })"
                        :key="index"
                    >
                        <div
                            v-for="(uu, uuIndex) in item"
                            :key="uuIndex"
                            :style="{
                                color:
                                    uu?.status == 'WAIT_START'
                                        ? '#fff'
                                        : getStatusColor(uu?.status),
                            }"
                        >
                            {{ uu?.desc }}
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="row">
                        <div v-for="(item, index) in rightColumns" :key="index">
                            {{ item.title }}
                        </div>
                    </div>
                    <div
                        class="row"
                        v-for="(item, index) in table.rightData?.filter((item, index) => {
                            return index >= i && index < i + step;
                        })"
                        :key="index"
                    >
                        <div v-for="(uu, uuIndex) in item" :key="uuIndex">
                            <span
                                :style="{
                                    color: uu.status
                                        ? uu.status == 'WAIT_PRODUCING'
                                            ? 'red'
                                            : getStatusColor(uu?.status)
                                        : '#fff',
                                }"
                            >
                                {{ uu.value }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import Select from "@/components/Select";
import { apiBigScreenProjectList, apiBigScreenWorkOrderProgresses } from "@/api";
import { getStatusColor } from "@/utils/common";
const leftColumns = [
    {
        title: "序号",
        key: "index",
    },
    {
        title: "项目名称",
        key: "projectName",
    },
    {
        title: "工单号",
        key: "code",
    },
    {
        title: "集成编号",
        key: "containerNo",
    },
];
const rightColumns = [
    {
        title: "工单备注",
        key: "remarks",
    },
    {
        title: "工单状态",
        key: "statusDesc",
    },
    {
        title: "投产日期",
        key: "actualStartDate",
    },
    {
        title: "完成日期",
        key: "actualEndDate",
    },
];
export default defineComponent({
    components: {
        Select,
    },
    setup() {
        const state = reactive({
            projectCode: "",
            projectList: [],
            workorderList: [],
            options: [],
            leftData: [],
            middleData: [],
            rightData: [],
            i: 0,
            step: 10,
            interValId: null,
        });

        const apiGetWorkOrderProgresses = async code => {
            let res = await apiBigScreenWorkOrderProgresses(code);
            if (res.status === "SUCCESS") {
                state.workorderList = res?.data || [];
                state.workorderList.forEach(item => {
                    item.leftData = item.dataList.map((uu, index) => [
                        index + 1 + state.i,
                        uu.projectName,
                        uu.code,
                        uu.containerNo,
                    ]);
                    item.middleData = item.dataList.map(uu =>
                        uu.stepInfos.map(step => ({
                            desc: step?.stepStatusDesc,
                            status: step?.stepStatus,
                        }))
                    );
                    item.rightData = item.dataList.map(uu => [
                        {
                            value: uu.remarks,
                        },
                        {
                            value: uu.statusDesc,
                            status: uu.status,
                        },
                        {
                            value: uu.actualStartDate,
                        },
                        {
                            value: uu.actualEndDate,
                        },
                    ]);
                });
                startInterVal();
            }
        };

        const getData = async () => {
            let res = await apiBigScreenProjectList();
            if (res.status === "SUCCESS") {
                state.projectList = res.data || [];
                if (state.projectList?.length) {
                    state.projectCode = state.projectList[0]?.key;
                    apiGetWorkOrderProgresses(state.projectList[0]?.key);
                }
            }
        };
        getData();

        setInterval(() => apiGetWorkOrderProgresses(state.projectCode), 60000);

        const startInterVal = () => {
            state.interValId = setInterval(() => {
                const _i = state.i + state.step;
                let maxData = state.workorderList.reduce((prev, cur) => {
                    return prev?.dataList?.length > cur?.dataList?.length ? prev : cur;
                });
                if (_i >= maxData?.dataList?.length) {
                    state.i = 0;
                } else {
                    state.i = _i;
                }
            }, 10000);
        };

        watch(
            () => state.projectCode,
            newVal => {
                state.i = 0;
                clearInterval(state.interValId);
                apiGetWorkOrderProgresses(newVal);
            }
        );

        return {
            ...toRefs(state),
            leftColumns,
            rightColumns,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
    display: none;
}
.bankname {
    height: 80px;
    text-align: center;
    font-size: 30px;
    padding: 20px 0;
    box-sizing: border-box;
    font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC", sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 36px;
    color: #ffffff;
    grid-area: g0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        height: 100%;
        flex: 1;
        width: 0;
    }
    .name {
        display: inline-block;
        flex: 1;
    }
}
.bg-img {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0);
}
:deep(.ant-empty-description) {
    color: #fff;
}
.container {
    overflow: scroll;
    padding: 0 20px;
    height: 100vh;
    min-width: 1500px;
    min-height: 100vh;
    background-image: url("../../../assets/back.png");
    background-size: 100% 100vh;
    background-repeat: no-repeat;
    .table-wrapper {
        display: flex;
        background: rgba(0, 0, 255, 0.09803921568627451);
        margin-bottom: 20px;
        .left,
        .middle,
        .right {
            .row {
                flex: 1;
                display: flex;
                > div {
                    flex: 1;
                    height: 40px;
                    padding: 10px 20px;
                    min-width: 150px;
                    color: #fff;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background: url("../../../assets/thback.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .middle {
            flex: 1;
            width: 500px;
            overflow-x: scroll;
        }
    }
}
</style>
